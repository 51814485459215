.nav-logo {
    width: 140px;
    height: auto;
}
.hero-section {
    position: relative;
    z-index: 2;
    /* height: 100vh; */
    height: 100vh;
    padding: 50px 20px;
    /* background-image: url("../assets/images/cream_on_skin_3_gradient.png"); */
    /* background-size: 85%;
    background-position: right top;
    background-repeat: no-repeat;
    background-attachment: fixed; */
    /* border: 2px solid green; */
    /* overflow: hidden; */
}
.hero-section::before { /* clipped image */
    background-image: url("../assets/images/interface.png");
    background-color: #efefef; 
    
    background-size: cover;
    background-position: top-right;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
    left: 0;
    height: 100vh;
    /* z-index: 2; */

    z-index: 1;
    /* -webkit-clip-path: polygon(0 0, 25% 0, 75% 100%, 0% 100%);
    clip-path: polygon(0 0, 25% 0, 75% 100%, 0% 100%); */
}
.hero-section::after { /* full image  */
    content: '';
    background-image: url("../assets/images/cream_on_skin_3_gradient2.png");
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    background-attachment: fixed;
	position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
    z-index: 1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2cb99a+0,3d98d3+100 */
    background: rgb(44,185,154); /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(44,185,154,0.8) 0%, rgba(61,152,211,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2cb99a', endColorstr='#3d98d3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+61,ffffff+61,2cb99a+62,2cb99a+62,3d98d3+100&1+61,0.5+62,0.5+62,0.5+100 */
    /* background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(44,185,154,0.8) 50%, rgba(61,152,211,0.8) 100%); FF3.6-15 */
    /* background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(44,185,154,0.8) 50%,rgba(61,152,211,0.8) 100%); Chrome10-25,Safari5.1-6 */
    /* background: linear-gradient(45deg, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(44,185,154,0.8) 50%,rgba(61,152,211,0.8) 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#803d98d3',GradientType=1 ); IE6-9 fallback on horizontal gradient */
}
.hero-container {
    position: relative;
    z-index: 2;
    /* padding: 20px; */
    text-align: left;
    /* position: absolute;
    left: 150px;
    top: 50%;
    transform: translateY(-50%); */
    /* padding-top: 35vh; */
    top: 25vh;
    /* padding-left: 20px;
    padding-right: 20px; */
    max-width: 1000px;
    /* width: 80vw; */
    padding-left: 5%;
}
/* .heroH1 {
    font-size: 85px;
} */
.hero-img-container {
    margin-bottom: 10px
}
.hero-logo {
    width: 400px;
    height: auto;
}
/* .hero-header {
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    line-height: 1.2em;
}
.hero-header::after {
    background: #0d97ff;
    display: block;
    content: '';
    height: 5px;
    width: 40px;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
}
.hero-sub-header {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    line-height: 32px;
    margin-bottom: 20px;
}
.heroCTA {
    color: #fff;
    background-color: #3d98d3;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    margin-top: 30px;
} */
.demo-section .hero-section-header {
    margin-bottom: 50px;
}
.demo-section { /* flows after .hero-section but above .hero-section::before & ::after overlays due to higher z-index */
    position: relative;
    z-index: 2;
    padding: 50px;
    margin-bottom: 50px;
    /* background-color: #f3f3f3; */
    /* border: 1px solid green; */
}
.demo-tab-container {
    margin: 50px 0;
    /* margin-bottom: 20px; */
}
.demo-tab {
    display: inline-block !important;
}
.demo-content-container {
    max-width: 900px;
    /* border:1px solid blue; */
    margin: 40px auto;
}
.demo-content-container p {
    margin-bottom: 30px
}
/* .demo-before, .demo-after {
    display: inline-block;
    width: 50%;
}
.demo-container img { 
    height: 300px;
    width: auto;
    margin: 0 auto;
} */

/* @media screen and (max-width: 1007px) {
    .hero-section .hero-header {
        font-size: 45px;
    } 
    .hero-section .hero-sub-header {
        font-size: 20px;
    } 
  } */

  @media screen and (max-width: 900px) {
    .hero-section {
        height: auto;
    }
    .hero-section .hero-header {
        font-size: 45px;
    } 
    .hero-section .hero-sub-header {
        font-size: 20px;
    }  
    .hero-section::before { /* clipped image */
        height: 100%;
    }
    .hero-section::after { /* full image  */
        height: 100%;
    }
    .hero-section .hero-container {
        top: inherit;
        padding-left: 0;
        /* padding-top: 10vh;
        padding-bottom: 10vh; */
    }
}

@media screen and (max-width: 750px) {
    .demo-before, .demo-after {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .demo-before {
        margin-bottom: 40px;
    }
}