.nav-bar {
    /* height: 53px; */
}
.nav-bar-wrapper {
    height: 50px;
    width: 100%;
    flex-direction: row;
}
.nav-logo {
    height: 23px;
    width: auto;
}
.logo {
    height: 23px;
    width: auto;
    margin-right: 20px;
}
.nav-bar .nav-link {
    text-transform: uppercase !important;
    font-size: 14px;
}
.nav-bar .logoLink {
    height: auto;
}
#nav-product-dropdown {
    text-transform: uppercase !important;
    font-size: 14px;
    color: #337ab7;
    text-decoration: none;
}