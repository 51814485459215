.hp-infobar {
    height: 200px;;
    padding: 50px;
    color: #fff;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2cb99a+0,3d98d3+100 */
    background: rgb(44,185,154); /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(44,185,154,1) 0%, rgba(61,152,211,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(44,185,154,1) 0%,rgba(61,152,211,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(44,185,154,1) 0%,rgba(61,152,211,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2cb99a', endColorstr='#3d98d3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.hp-infobar h1 {
    font-size: 50px;
}
.hp-hero-section {
    position: relative;
    z-index: 2;
    height: calc(100vh - 50px - 200px);
    padding: 100px 50px;
    background-image: url("../assets/images/02232019_hero_image_gradient11.jpg");
    background-size: cover;
    /* background-position: left top; */
    background-position: left -150px;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.hp-hero-section::before {
    display: none;
    background-color: #efefef; 
    
    background-size: cover;
    background-position: top-right;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    z-index: 1;
}
.hp-hero-section::after {
    display: none;
    content: '';
    background-size: cover;
    background-position: right top;
    background-position: left top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
    z-index: 1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2cb99a+0,3d98d3+100 */
    background: rgb(44,185,154); /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(44,185,154,0.8) 0%, rgba(61,152,211,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2cb99a', endColorstr='#3d98d3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
} 
.hp-hero-container {
    position: relative;
    z-index: 2;
    text-align: left;
    top: 10vh;
    max-width: 1000px;
    padding-left: 5%;
}
.hp-hero-section .hero-header {
    color: #fff;
}
.hp-hero-section .heroCtaDemo {
    margin-left: 20px;
}
.hp-news-section {
    position: relative;
}
.hp-news-section article {
    position: absolute;
    z-index: 3;
    width: 100%;
    bottom: 0;
    background-color: rgba(240,240,240,.4);
}
.hp-news-section p {
    font-size: 20px;
    margin: 0px auto;
    max-width: 900px;
    text-align: left;
    color: #fff;
}
.hp-news-section .section-header {
    font-size: 30px;
    margin: 0px auto;
    margin-left: auto;
    margin-right: auto;
}
.hp-news-section h2::after {
    background: #2b7bf2;
    display: block;
    content: '';
    height: 5px;
    width: 40px;
    margin: 15px auto 25px auto !important;
}
.hp-news-section .carousel-item {
    width: auto;
    height: 120px;
    padding: 15px;
}
.hp-news-section .carousel-indicators {
    bottom: 10px !important;
}
.find-out-more-section {
    position: relative;
    padding: 100px 50px;
}
.find-out-more-section::after {
    content: '';
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
    background: #349980;
    background: -moz-linear-gradient(45deg, #349980 0%,#3f3997 100%);
    background: -webkit-linear-gradient(45deg, #349980 0%,#3f3997 100%);
    background: linear-gradient(45deg, #349980 0%,#3f3997 100%);
    filter: brightness(130%);
}
.find-out-more-section .find-out-more-container {
    text-align: left;
    max-width: 1000px;
    padding-left: 5%;
}
.find-out-more-section h1, .find-out-more-section p {
    color: #fff;
}
.steps-section ol.steps {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: steps;
}
.steps-section li.step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-justify: center;
    justify-content: center;
    margin-bottom: 30px;
}
.steps-section .step-description-container {
    position: relative;
    padding-left: 70px;
    max-width: 465px;
    box-sizing: border-box;
    text-align: left;
}
.steps-section li.step-1 .step-description-container, .steps-section li.step-3 .step-description-container {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}
.steps-section .step-description::before {
    counter-increment: steps;
    content: counter(steps);
    color: #ececec;
    font-weight: 800;
    font-size: 260px;
    position: absolute;
    top: -50px;
    left: 0;
    line-height: 1;
    z-index: -1;
}
.steps-section h3 {
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -1px;
}
.steps-section p {
    font-size: 19px;
}
.hp-learn-more-section {
    position: relative;
    padding: 100px 50px;
}
.hp-learn-more-section .smartphone {
    overflow: hidden;
    padding-top: 25px;
    border-radius: 6px;
    box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    display: block;
    width: 350px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding: 55px 13px;
    border-radius: 44px;
    will-change: transform;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
    width: 324px;
    height: 579px;
    box-sizing: content-box;
}
.hp-learn-more-section .smartphone img {
    width: 324px;
    height: 579px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    cursor: pointer;
} 
.hp-learn-more-section .smartphone img#hand-focused-lg {
    opacity: 0;
    z-index: -1;
    transition: all .5s;
}
.hp-learn-more-section .smartphone img#background-focused-lg {
    opacity: 1;
    z-index: 1;
    transition: all .5s;
}
.hp-learn-more-section .smartphone img#hand-focused-md {
    display: none;
}
.hp-learn-more-section .smartphone img#background-focused-md {
    display: none;
}
.hp-learn-more-section .smartphone img#hand-focused-sm {
    display: none;
}
.hp-learn-more-section .smartphone img#background-focused-sm {
    display: none;
}
.hp-learn-more-section .app-image-container {
    width: 50%;
    height: auto;
    z-index: 1;
    display: inline-block;
}
.hp-learn-more-section .app-content-container {
    margin: 0px;
    padding: 100px 100px 0px 0px;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}
.hp-learn-more-section img {
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    width: 400px;
}
.hp-learn-more-section .app-image-container p {
    margin-top: 30px;
    font-weight: bold;
} 
.hp-technology-section {
    padding: 100px 50px;
    position: relative;
}
.hp-technology-section .section-header {
    margin-bottom: 100px;
}
.hp-technology-section .hp-technology-content-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.hp-technology-section .demo-container {
    margin: auto;
    margin-bottom: 100px;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    align-items: flex-start;
    -webkit-flex-wrap: wrap; 
    -moz-justify-content: wrap;
    -ms-justify-content: wrap;
    flex-wrap: wrap;
}
.hp-technology-section .demo-container-back {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.hp-technology-section .demo-container-alignment {
    margin-bottom: 0px;
}
.hp-technology-section .demo-container-images {
    position: relative;
    width: 400px;   
    height: 400px; 
    display: inline-block;
    margin-bottom: 50px;
}
.hp-technology-section .demo-container-text {
    text-align: left;
    display: inline-block;
    width: 420px;
    position: relative;
    top: 10%;
    padding: 50px;
    box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    background-color: white;
}
.hp-technology-section .demo-container-text::after {
    content: "";
    position: absolute;
    width: 110%;
    height: 110%;
    z-index: -5;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2cb99a+51,3d98d3+96&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(44,185,154,0) 0%, rgba(44,185,154,0.51) 51%, rgba(61,152,211,0.96) 96%, rgba(61,152,211,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(44,185,154,0) 0%,rgba(44,185,154,0.51) 51%,rgba(61,152,211,0.96) 96%,rgba(61,152,211,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(44,185,154,0) 0%,rgba(44,185,154,0.51) 51%,rgba(61,152,211,0.96) 96%,rgba(61,152,211,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002cb99a', endColorstr='#3d98d3',GradientType=0 ); /* IE6-9 */
}
.hp-technology-section .demo-container-text-odd::after {
    top: 10%;
    left: 10%;
}
.hp-technology-section .demo-container-text-even::after {
    top: 10%;
    right: 10%;
}
.hp-technology-section .img-after {
    z-index: -1;
}
/* The animation code */
@keyframes before-after {
    from {opacity: 1;}
    to {opacity: 0;}
}
.hp-technology-section .img-before {
    opacity: 1;
    animation-name: before-after;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.7, 0, 0, 1);
    animation-delay: 1s;
}
.hp-technology-section .demo-container-images img { /* placeholder */
    max-height: 425px;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
}
.hp-technology-section .demo-container-images-even img { /* placeholder */
    left: 0;
}
.hp-technology-section .demo-container-images-odd img { /* placeholder */
    right: 0;
}
.hp-technology-section .demo-container-images { /* placeholder */
    box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
}
.hp-technology-section .hp-technology-content-container h2::after {
    margin-left: auto;
    margin-right: auto;
}
.hp-technology-section .hp-technology-content-container .hero-subheader {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin: 0px auto 50px auto;
    max-width: 1200px;
    text-align: left;
}
.hp-technology-section .hp-technology-image-container img {
    max-width: 1200px;
    width: 100%;
    height: auto;
}

.hp-our-technology-section {
    padding: 100px 50px 0px 50px;
    position: relative;
}
.hp-our-technology-section .section-header {
    margin-bottom: 100px;
}
.hp-our-technology-section .hp-technology-content-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.hp-our-technology-section .demo-container {
    margin: auto;
    margin-bottom: 100px;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    align-items: flex-start;
    -webkit-flex-wrap: wrap; 
    -moz-justify-content: wrap;
    -ms-justify-content: wrap;
    flex-wrap: wrap;
}
.hp-our-technology-section .demo-container-back {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.hp-our-technology-section .demo-container-alignment {
    margin-bottom: 0px;
}
.hp-our-technology-section .demo-container-images {
    position: relative;
    width: 400px;   
    /* height: 400px;  */
    display: inline-block;
    margin-bottom: 50px;
}
.hp-our-technology-section .demo-container-text {
    text-align: left;
    display: inline-block;
    width: 420px;
    position: relative;
    top: 10%;
    padding: 50px;
    box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    background-color: white;
}
.hp-our-technology-section .demo-container-text::after {
    content: "";
    position: absolute;
    width: 110%;
    height: 103%;
    z-index: -5;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2cb99a+51,3d98d3+96&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(44,185,154,0) 0%, rgba(44,185,154,0.51) 51%, rgba(61,152,211,0.96) 96%, rgba(61,152,211,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(44,185,154,0) 0%,rgba(44,185,154,0.51) 51%,rgba(61,152,211,0.96) 96%,rgba(61,152,211,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(44,185,154,0) 0%,rgba(44,185,154,0.51) 51%,rgba(61,152,211,0.96) 96%,rgba(61,152,211,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002cb99a', endColorstr='#3d98d3',GradientType=0 ); /* IE6-9 */
}
.hp-our-technology-section .demo-container-text-odd::after {
    top: 10%;
    left: 10%;
}
.hp-our-technology-section .demo-container-text-even::after {
    top: 10%;
    right: 10%;
}
.hp-our-technology-section .demo-container-images img { /* placeholder */
    height: 425px;
    width: auto;
    margin: 0 auto;
    /* position: absolute; */
    /* top: 0; */
    box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
}
.hp-our-technology-section .demo-container-acne-images {
    /* height: 250px; */
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    align-items: center;
}
.hp-our-technology-section .demo-container-images .acne-photo {
    width: 90px;
    height: auto;
    margin: 0px;
}
.hp-our-technology-section .demo-container-images-even img { /* placeholder */
    left: 0;
}
.hp-our-technology-section .demo-container-images-odd img { /* placeholder */
    right: 0;
}
.hp-our-technology-section .demo-container-images { /* placeholder */
    /* box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3); */
}
.hp-our-technology-section .hp-technology-content-container h2::after {
    margin-left: auto;
    margin-right: auto;
}
.hp-our-technology-section .hp-technology-content-container .hero-subheader {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin: 0px auto 50px auto;
    max-width: 1200px;
    text-align: left;
}

.hp-partners-section {
    /* padding: 100px 50px; */
    padding: 100px 50px 50px 50px;
}
.hp-partners-section .section-header {
    text-align: left;
    /* padding-left: 5%; */
}
.hp-partners-section .partners-container {
    margin-top: 100px;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    align-items: flex-start;
    -webkit-flex-wrap: wrap; 
    -moz-justify-content: wrap;
    -ms-justify-content: wrap;
    flex-wrap: wrap;
}
.hp-partners-section .partner-container {
    box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    /* padding: 20px 65px; */
    padding: 20px 30px;
    box-sizing: content-box;
    background: rgb(44,185,154);
    background: -webkit-linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%);
    background: linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%);
    border-radius: 10px;
    margin-right: 20px;
}
.hp-partners-section .partner-container img {
    height: 100px;
    width: auto;
}
.hp-partners-section .partner-container:last-child {
    margin-right: 0;
}
.hp-partners-section .partner-container.partner-aid {
    padding: 10px 20px 30px 20px;
}
@media screen and (max-width: 1600px) {
    .hp-partners-section .partners-container {
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }
    .hp-partners-section .partner-container {
        margin-bottom: 50px;
    }
}
@media screen and (max-width: 1200px) {
    .hp-partners-section .section-header {
        text-align: center;
        padding-left: 0;
    }
    .hp-partners-section .section-header::after {
        margin-left: auto;
        margin-right: auto;
    }
    .hp-learn-more-section .app-content-container {
        padding: 50px 50px 0px 50px;
    }
    .hp-partners-section .partners-container {
        -webkit-justify-content: center;
        justify-content: center;
    }
    .hp-partners-section .partner-container {
        width: 370px;
        margin: 10px;
    }
    .hp-partners-section .partner-container, .hp-partners-section .partner-container:last-child {
        margin: 10px;
        margin-bottom: 50px;
        /* padding: 20px 0px; */
    }
    /* .hp-partners-section .partner-container:last-child {
        margin-bottom: 0px;
    } */
    .hp-partners-section .partner-container.partner-aid {
        padding: 10px 30px 30px 30px;
    }
}
@media screen and (max-width: 900px) {
    .section-header {
        font-size: 35px;
    }
    .hp-hero-section {
        height: 550px;
        background-position: center -60px;
        background-size: 1000px;
    }
    .hp-hero-section .hp-hero-header {
        font-size: 45px;
    } 
    .hp-hero-section .hp-hero-sub-header {
        font-size: 20px;
    }  
    .hp-hero-section::before {
        height: 100%;
    }
    .hp-hero-section::after {
        height: 100%;
    }
    .hp-hero-section .hp-hero-container {
        top: inherit;
        padding-left: 0;
    }
    .hp-news-section .section-header {
        font-size: 25px;
    }
    .hp-news-section p {
        font-size: 18px;
    }
    .hp-learn-more-section {
        padding: 100px 50px 0px 50px;
    }
    .steps-section li.step {
        display: block;
        margin-bottom: 50px;
    }
    .steps-section .step-description-container {
        margin: 0px auto 12px auto;
        max-width: 500px;
    }
    .steps-section .step-description::before {
        top: -15px;
        font-size: 100px;
        left: 0px;
    }
    .steps-section h3 {
        margin-top: 0px;
        font-size: 23px;
    }
    .stepsSection h3 {
        margin-top: 0px;
        font-size: 23px;
    }
    .stepsSection p {
        font-size: 17px;
    }
    .hp-technology-section .demo-container-text::after {
        width: 100%;
        height: 100%;
    }
    .hp-learn-more-section .app-image-container {
        width: 100%;
    }
    .hp-learn-more-section .app-content-container {
        width: 100%;
        padding-top: 80px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .hp-learn-more-section .app-image-container {
        width: 300px;
    }
    .hp-learn-more-section .smartphone {
        width: 274px;
        height: 489px;
    }
    .hp-learn-more-section .smartphone img {
        width: 274px;
        height: 489px;
    } 
    .hp-learn-more-section .smartphone img#hand-focused-lg {
        display: none;
    }
    .hp-learn-more-section .smartphone img#background-focused-lg {
        display: none;
    }
    .hp-learn-more-section .smartphone img#hand-focused-md {
        display: inline;
        /* opacity: 0; */
        z-index: -1;
        transition: all .5s;
    }
    .hp-learn-more-section .smartphone img#background-focused-md {
        display: inline;
        /* opacity: 1; */
        z-index: 1;
        transition: all .5s;
    }
    .hp-partners-section .partners-container {
        margin-top: 100px;
        display: -webkit-flex;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        align-items: center;
        -webkit-flex-wrap: wrap; 
        -moz-justify-content: wrap;
        -ms-justify-content: wrap;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .hp-hero-section {
        padding: 50px 15px;
        height: 450px;
        background-position: center -100px;
    }
    .hero-header {
        font-size: 30px;
    }
    .section-header {
        font-size: 25px;
    }
    .hp-news-section .section-header {
        font-size: 20px;
    }
    .hp-news-section .carousel-item {
        height: 100px;
    }
    .hp-news-section .carousel-indicators {
        bottom: 0px !important;
    }
    .hp-learn-more-section {
        /* padding: 50px 15px; */
        padding: 50px 15px 0px 15px;
    }
    .hp-learn-more-section .app-content-container {
        padding: 50px 0px 0px 0px;
    }
    .hp-learn-more-section img {
        width: 100%;
    }
    .hp-learn-more-section .app-image-container {
        width: 225px;
    }
    .hp-learn-more-section .smartphone {
        width: 199px;
        height: 355px;
    }
    .hp-learn-more-section .smartphone img {
        width: 199px;
        height: 355px;
    }
    .hp-learn-more-section .smartphone img#hand-focused-md {
        display: none;
    }
    .hp-learn-more-section .smartphone img#background-focused-md {
        display: none;
    }
    .hp-learn-more-section .smartphone img#hand-focused-sm {
        display: inline;
        /* opacity: 0; */
        z-index: -1;
        transition: all .5s;
    }
    .hp-learn-more-section .smartphone img#background-focused-sm {
        display: inline;
        /* opacity: 1; */
        z-index: 1;
        transition: all .5s;
    }
    .hp-technology-section {
        padding: 50px 15px;
    }
    .hp-technology-section .hp-technology-content-container .hero-subheader {
        font-size: 15px;
        line-height: 20px;
    }
    .hp-technology-section .demo-container {
        -webkit-justify-content: space-evenly;
        justify-content: space-evenly;
    }
    .hp-technology-section .demo-container-images {
        width: 300px;
        height: 300px;
    }
    .hp-technology-section .demo-container-images {
        margin-bottom: 20px;
    }   
    .hp-technology-section .demo-container-text {
        width: 90%;
    } 
    .hp-technology-section .demo-container-text::after {
        width: 95%;
        height: 95%;
    }
    .hp-technology-section .demo-container-text-odd::after {
        top: 10%;
        left: 10%;
    }
    .hp-technology-section .demo-container-text-even::after {
        top: 10%;
        right: 10%;
    }
    .hp-partners-section {
        padding: 50px 15px;
    }
    .hp-partners-section .partner-container {
        width: 80%;
        height: auto;
        box-sizing: border-box;
    }
    .hp-partners-section .partner-container img {
        height: 60px;
        width: auto;
    }
    .hp-news-section p {
        font-size: 13px;
    }
    .find-out-more-section {
        padding: 50px 15px;
    }
    .find-out-more-section .find-out-more-container {
        padding-left: 0;
    }
    .find-out-more-section p {
        font-size: 16px;
    }
    footer {
        padding: 50px 15px;
    }
    .footer-links ul {
        height: 120px;
    }
}
