@import url('https://fonts.googleapis.com/css?family=Muli:400,700');

body {
  margin: 0;
  padding: 0;
  font-family: "Muli", "Helvetica Neue", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3f3f3f !important;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
h1, h2, h3 {
  font-weight: bold !important;
}
h2 {
  margin-top: 0px;
}
p {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px;
}
.hero-section-header {
  text-align: left;
}
.hero-header {
  font-size: 60px;
  font-weight: 700;
  /* margin-bottom: 30px; */
  /* color: #fff; */
  line-height: 1.2em;
  margin-top: 0px;
  /* padding: 10px; */
  /* background-color: #fff; */
}
.hero-header::after, .section-header::after {
  background: #2b7bf2;
  display: block;
  content: '';
  height: 5px;
  width: 40px;
  margin-bottom: 25px !important;
  margin-top: 15px !important;
}
.hero-sub-header {
  font-size: 24px;
  font-weight: 400;
  /* color: #fff; */
  line-height: 32px;
  margin-bottom: 20px;
}
.heroCTA {
  color: #fff;
  background-color: #3db1d3;
  font-size: 16px;
  /* font-weight: 700; */
  /* padding: 10px 20px; */
  padding: 11px 25px;
  border: 1px solid #3db1d3;
  border-radius: 5px;
  border: none;
  /* text-transform: uppercase; */
  /* margin-top: 30px; */
  /* height: 34px; */
  vertical-align: bottom;
}
.section-header {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2em;
  margin-top: 0px;
  margin-bottom: 15px;
}
.emailSubmit {
  color: #fff;
  background-color: #3db1d3;
  font-size: 16px;
  padding: 0px 25px;
  height: 34px;
  border: 1px solid #3db1d3;
  border-radius: 5px;
  border: none;
  vertical-align: bottom;
  margin-left: 5px;
}
.signup-form input {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  height: 44px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin-right: 5px;
}

.signup-form button {
  color: #fff;
  /* background-color: #5da9da; */
  background-color: #2b7bf2;
  font-size: 18px;
  padding: 0px 25px;
  height: 44px;
  border: 2px solid #2b7bf2;
  -webkit-transition: all .4s;
  transition: all .4s;
  border-radius: 5px;
  vertical-align: bottom;
}
.signup-form button:hover {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  /* -webkit-box-shadow: inset 0px 0px 0px 5px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 0px 0px 5px rgba(0,0,0,0.75);
  box-shadow: inset 0px 0px 0px 3px #fff; */
}

@media screen and (max-width: 1200px) {
  .section-header {
    font-size: 40px;
  }
}
@media screen and (max-width: 900px) {
  .hero-header {
    font-size: 40px;
  }
}
@media screen and (max-width: 700px) {
  .heroCTA {
    margin-top: 0px;
  }
}
@media screen and (max-width: 500px) {
  p {
    font-size: 15px;
    line-height: 20px;
  }
  .hero-header {
    font-size: 30px;
  }
  .hero-sub-header {
    margin-bottom: 10px;
    line-height: 20px;
  }
  .signup-form input {
    width: 100%;
    margin-bottom: 15px;
  }
  .signup-form button {
    width: 100%;
  }
}

