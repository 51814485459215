.pulse-btn-container {
    /* float: left; */
    /* margin: 40px; */
    font: 13px/130px 'Barlow Semi Condensed', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    /* border: 5px solid rgba(200,200,200, 1); */
    animation: shadow-pulse 1s infinite;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    width: 10px;
    height: 10px;
    background-color: rgb(255, 255, 255);
    padding: 2em;
    border: 10px solid rgba(0, 0, 0, 0.3);
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.glyph-camera {
    color: rgb(44, 185, 154);
    position: absolute;
    font-size: 25px;
    text-align: center;
    vertical-align: top;
    /* padding: 20px; */
    left: 50;
    transform: translateX(-50%);
    top: 25%;
    /* border:1px solid blue; */
    display: inline-block;
}

.paused {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
    animation: none;
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    100% {
        /* box-shadow: 0 0 0 35px rgba(0, 0, 0, 0); */
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);

    }
}

@media screen and (max-width: 500px) {
    .pulse-btn-container {
        padding: 1.6em;
        bottom: 15%;
    }
    .glyph-camera {
        font-size: 20px;
    }
}
