.investors-hero-section {
    position: relative;
    z-index: 2;
    height: calc(60vh - 50px);
    padding: 100px 50px;
    background-image: url("../assets/images/hero_image_alex_smart_object_gradient.jpg");
    background-size: cover;
    background-position: left -160px;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.investors-hero-container {
    position: relative;
    z-index: 2;
    text-align: left;
    top: 8vh;
    max-width: 1000px;
    padding-left: 5%;
}
.investors-hero-section .hero-header {
    color: #fff;
}
.investors-hero-section .heroCtaDemo {
    margin-left: 20px;
}
.investors-hero-section p {
    color: #fff;
}
.team-section {
    padding-top: 100px;
    position: relative;
}
/* .team-section::after {
    content: "";
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
    width: 100%;
    height: 449px;
    background-color: #efefef;
} */
.team-section .section-header {
    text-align: left;
    padding-left: 5%;
    padding-bottom: 30px;
}
#team-leadership-container, #team-advisors-container {
    position: relative;
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    align-items: space-evenly;
}
#team-advisors-container {
    height: 326px;
}
#team-leadership-container {
    margin-bottom: 70px;
    height: 370px;
}
#team-leadership-container img {
    /* background: rgb(44,185,154);
    background: -webkit-linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%);
    background: linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%); */
    width: 200px;
}
#team-advisors-container img {
    height: 200px;
    width: auto;
}
.team-containers {
    position: relative;
}
.team-containers::after {
    content: "";
    display: block;
    background: rgb(44,185,154);
    background: -webkit-linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%);
    background: linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%);
    height: 126px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    /* display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    align-items: center; */
    -webkit-box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    /* -webkit-flex-wrap: wrap; 
    -moz-justify-content: wrap;
    -ms-justify-content: wrap;
    flex-wrap: wrap; */
}
.team-member-description-container {
    width: 200px;
    height: 80px;
    position: relative;
    top: 40px;
    z-index: 2;
    vertical-align: middle;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: start;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
}
.advisor-description-container {
    width: 330px;
}
.team-member-description-container span {
    color: #fff;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    font-size: 16px;
}
.team-member-description-container .team-member-name {
    font-weight: bold;
}
.team-member-description-container .team-member-title {
    font-style: italic;
}
#team-advisors-container .team-member-description-container {
    top: 30px;
}

@media screen and (max-width: 1400px) {
    .investors-hero-section {
        /* height: 100vh; */
        /* background-position: left -60px; */
        background-size: 1400px;
    }
}
@media screen and (max-width: 1200px) {
    .investors-hero-section {
        /* height: 100vh; */
        /* background-position: left -60px; */
        background-size: 1200px;
    }
    #team-leadership-container {
        height: 297px;
    }
    #team-leadership-container img {
        width: 140px;
    }
    #team-advisors-container {
        height: 276px;
    }
    #team-advisors-container img {
        height: 150px;
        width: auto;
    }
    .team-member-description-container {
        width: 180px;
        top: 35px;
    }
    .advisor-description-container {
        width: 325px;
    }
}
@media screen and (max-width: 950px) {
    .investors-hero-section {
        height: 400px;
        background-position: center -60px;
        background-size: 900px;
    }
    .investors-hero-container {
        position: relative;
        z-index: 2;
        text-align: left;
        top: 0vh;
        max-width: 1000px;
        padding-left: 5%;
    }
    .investors-hero-section .investors-hero-header {
        font-size: 45px;
    } 
    .investors-hero-section .investors-hero-sub-header {
        font-size: 20px;
    }  
    .investors-hero-section::before { /* clipped image */
        height: 100%;
    }
    .investors-hero-section::after { /* full image  */
        height: 100%;
    }
    .investors-hero-container {
        padding-left: 0;
    }
    .team-containers::after {
        display: none;
    }
    .team-containers {
        height: auto;
        width: 100%;
        flex-wrap: wrap;
    }
    .team-member-description-container {
        width: auto;
        height: auto;
        padding: 10px;
        text-align: left;
        margin-bottom: 30px;
        position: static;
        background: cadetblue;
        -webkit-box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
        -moz-box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
        box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    }
    /* .team-member-description-container h4 {
        margin-right: auto;
    } */
    .team-member-container {
        margin-bottom: 30px;
        padding-top: 20px;
        width: 40%;
        display: inline-block;
        text-align: center;
        background: rgb(44,185,154);
        background: -webkit-linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%);
        background: linear-gradient(45deg, rgba(44,185,154,0.8) 0%,rgba(61,152,211,0.8) 100%);
        -webkit-box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
        -moz-box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
        box-shadow: 0 22px 100px -15px rgba(0, 0, 0, .3);
    }
    /* #team-description-container {
        width: 40%;
        height: auto;
        vertical-align: top;
        display: inline-block;
        background: none;
        box-shadow: none;
    } */
    #team-leadership-container, #team-advisors-container {
        height: auto;
    }
}

@media screen and (max-width: 500px) {
    .investors-hero-section {
        padding: 50px 15px;
        height: 325px;
    }
    .investors-hero-section p {
        font-size: 16px;
    }
    .investors-hero-container {
        top: 0vh;
    }
    .team-member-description-container p {
        font-size: 15px;
    }
    #team-advisors-container img {
        height: 140px;
        width: auto;
    }
    
}