footer {
    /* padding: 100px 50px; */
    padding: 50px 50px;
    background-color: #f3f3f3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-justify: center;
    justify-content: center;
}
.footer-content {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -ms-flex-justify: space-around;
    justify-content: space-around;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0px auto;
    position: relative;
}
.footer-description h3 {
    font-size: 20px;
    font-weight: normal !important;
    margin: 20px 0 30px 0;
}
.footer-gif {
    /* padding: 30px; */
    position: relative;
    z-index: 1;
}
.footer-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-justify-content: center;
    -ms-flex-justify: center;
    justify-content: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    text-align: left;
    position: relative;
}
.footer-links::before {
    content: "";
    display: block;
    position: absolute;
    border-bottom: 1px solid #aeaeae;
    width: 100%;
    height: 10px;
    top: -95px;
}
.footer-links ul {
    padding: 0px 30px;
}
.footer-links li {
    list-style: none;
}
.footer-link-header {
    margin-bottom: 30px;
}
.footer-social {
    text-align: left;
}
.footer-gif img {
    width: 70px;
    padding: 0 5px 0 15px;
    box-sizing: content-box;
    background-color: #f3f3f3;
}
@media screen and (max-width: 1000px) {
    .footer-content {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .footer-description h3 {
    }
    .footer-gif {
    }
    .footer-links ul {
        padding: 0px 10px;
    }
    .footer-links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: center;
        -ms-flex-justify: center;
        justify-content: center;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        text-align: left;
    }
}