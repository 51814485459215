.derm-hero-section {
    /* height: 100vh; */
    height: 100vh;
    padding: 120px 20px 50px 20px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+61,ffffff+61,2cb99a+62,2cb99a+62,3d98d3+100&1+61,0.5+62,0.5+62,0.5+100 */
    /* background: -moz-linear-gradient(45deg, rgba(44,185,154,0.8) 50%, rgba(61,152,211,0.8) 100%); FF3.6-15 */
    /* background: -webkit-linear-gradient(45deg, rgba(44,185,154,0.8) 50%,rgba(61,152,211,0.8) 100%); Chrome10-25,Safari5.1-6 */
    /* background: linear-gradient(45deg, rgba(44,185,154,0.8) 50%,rgba(61,152,211,0.8) 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#803d98d3',GradientType=1 ); IE6-9 fallback on horizontal gradient */
    background-image: url("../assets/images/cream_on_skin.jpg");
    background-size: cover;
    position: relative;
    z-index: 1;
    /* margin-bottom:  100px; */
    /* border: 1px solid red; */
}
.derm-hero-section::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    background: -moz-linear-gradient(45deg, rgba(44,185,154,0.8) 50%, rgba(61,152,211,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(44,185,154,0.8) 50%,rgba(61,152,211,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(44,185,154,0.8) 50%,rgba(61,152,211,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#803d98d3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    width: 100vw;
    height: 100vh;
}
.derm-hero-section .hero-section-header {
    margin-bottom: 80px;
}
.derm-hero-section .derm-hero-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -ms-flex-justify: space-around;
    justify-content: space-around;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    /* border: 1px solid blue; */
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 0px 20px; */
    position: relative;
    z-index: 3;
}
.derm-hero-section .derm-demo {
    margin-right: 30px;
}
.derm-steps { /* placeholder */
    margin-left: 100px;
    /* z-index: 2; */
    /* position: relative; */
    /* height: 100%; */
    /* border: 1px solid blue; */
}
.derm-steps img { /* placeholder */
    /* display: block; */
    width: 100%;
    height: auto;
    /* border: 2px solid blue; */
    margin: 0 auto;
}
.derm-steps .step-image-container { /* placeholder */
    padding: 0px 30px;
}
.derm-steps ol.steps {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: steps;
}
.derm-steps li.step {
    /* border: 1px solid red; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-justify-content: center;
    -ms-flex-justify: center;
    justify-content: center;
    margin-bottom: 30px;
}
.derm-steps .step-description-container {
    position: relative;
    /* -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; */
    /* z-index: 2; */
    padding-left: 70px;
    min-width: 450px;
    box-sizing: border-box;
    text-align: left;
    width: 100%;
}
/* .derm-steps li.step-1 .step-description-container, .derm-steps li.step-3 .step-description-container {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
} */
.derm-steps .step-description::before {
    counter-increment: steps;
    content: counter(steps);
    color: #ececec;
    font-weight: 800;
    font-size: 180px;
    /* font-size: 100px; */
    position: absolute;
    top: -30px;
    left: 0;
    line-height: 1;
    z-index: -1;
}
.derm-steps h3 {
    font-size: 36px;
    letter-spacing: -1px;
}




.demo-steps-section {
    max-width: 900px;
    padding: 0px 50px;
    margin: 0px auto;
    position: relative;
}
.demo-steps-section .steps-container {
    position: relative;
}
.demo-steps-section .step-container {
    margin-top: 10px;
    position: absolute;
}
.demo-steps-section .step-container-1 {
    /* top: 100px; */
    top: 5%;
}
.demo-steps-section .step-container-2 {
    /* top: 750px; */
    top: 35%;
}
.demo-steps-section .step-container-3 {
    /* top: 1350px; */
    top: 65%;
}
.demo-steps-section .step-number {
    float: left;
    margin-right: 50px;
    /* background: rgb(44, 185, 154); */
    background: #baefe3;
    /* height: 30px;
    width: 30px; */
    height: 60px;
    width: 60px;
    /* line-height: 30px; */
    line-height: 60px;
    border-radius: 100px;
    font-weight: 700;
    color: white;
    text-align: center;
    font-size: 40px;
}
.demo-steps-section .step {
    display: table-row;
    vertical-align: middle;
    text-align: left;
    /* color: #fff; */
}
.demo-steps-section .step img {
    width: 600px;
    height: auto;
}
.demo-steps-section .step-title{
    font-weight: 700;
    margin-top: 0px;
}
.demo-steps-section .step-content {
    /* padding: 20px; */
}
.demo-steps-section .step-text {
    font-weight: 400;
    font-size: 16px;
}
.demo-steps-section .vertical-line {
    background: #baefe3;
    z-index: -1;
    width: 3px;
    /* height: 100%; */
    height: 1950px;
    /* height: auto; */
    /* position:absolute; */
    position: relative;
    /* left: 15px; */
    left: 28px;
}
.demo-steps-section .white-line {
    background: #FFF;
    z-index: -1;
    top: 0px;
    /* width:3px; */
    /* height:100%; */
    height: 2000px;
    position: absolute;
    left: 15px;
}


@media screen and (max-width: 900px) {
    .derm-hero-section {
        height: auto;
        padding-top: 50px;
    }
    .derm-hero-section::after {
        height: 100%;
    }
    .derm-hero-section .derm-hero-container {

    }
    .derm-hero-section .derm-demo {
        margin-right: 20px;
    }
    .derm-hero-section .derm-demo img {
       width: 250px;
       height: auto;
    }
    .derm-hero-section .hero-header {
        font-size: 45px;
    }
    .derm-hero-section .hero-sub-header {
        font-size: 20px;
    }
    .derm-hero-section .hero-sub-header {
        font-size: 20px;
    }
    .demo-steps-section {
        padding: 50px;
    }
    .demo-steps-section .step-container {
        margin-bottom: 100px;
    }
    .demo-steps-section .step-container-3 {
        margin-bottom: 0px;
    }
    .demo-steps-section .step-number {
        position: absolute;
    }
    .demo-steps-section .step-content {
        padding-left: 90px;
    }
    .demo-steps-section .step img {
        width: 100%;
        height: auto;
    }
    .demo-steps-section .vertical-line {
        display: none;
    }
    .demo-steps-section .step-container {
        margin-top: 10px;
        position: relative;
    }
}

@media screen and (max-width: 650px) {
    .derm-hero-section .derm-hero-container {
        flex-direction: column-reverse;
    }   
    .derm-hero-section .derm-demo {
        margin: auto;
    }
}